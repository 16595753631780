import { useState, useEffect } from "react";
import {
  FormGroup,
  Col,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { asyncWrap } from "../utils/utils";
import moment from "moment";
import Select from "react-select";
import { convertManagerForSelect } from "./utils";
import FormikInput from "./FormikInput";
import * as Yup from "yup";
import { message, Spin, Card } from "antd";
import Swal from "sweetalert2";

const AddEndUser = (props) => {
  const [district, setDistrict] = useState([]);
  const [user, setUser] = useState({});
  const { id, userId } = useParams();
  const [areas, setAreas] = useState([]);
  const [distArea, setDistArea] = useState([]);
  const [kcUsers, setKcUsers] = useState([]);
  const [state, setStates] = useState([]);
  const [stateDist, setStateDist] = useState([]);
  const [registerFields, setRegisterFields] = useState(null);
  const [regRoleId, setRegRoleId] = useState(id);
  // const [isRetailerRequired, setIsRetailerRequired] = useState<string>();
  const [intialfileUrl, setIntialfileUrl] = useState();
  const [panVerification, setPanVerification] = useState(false);
  const [kycAutomaticVerification, setKycAutomaticVerification] = useState("");

  const [cmsValues, setCmsValues] = useState();
  const [userApproved, setUserApproved] = useState(false);

  const getUserDetails = async () => {
    //eslint-disable-next-line
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire(
        "Session Expired",
        "Please logout. Your session is expired.\n\nGo to the left menu - See the logout option below settings - Click on logout and log in again.",
        "warning"
      );

      return;
    }
    if (!result) {
      return;
    }
    setUserApproved(result.data.data?.callverification);
  };

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setCmsValues(result.data.data);
  };

  useEffect(() => {
    getCmsValues();
    getUserDetails();
  }, []);

  const location = useLocation();

  const fetchKcData = async id => {
    const [error, result] = await asyncWrap(
      axios.get(`/user/list?pageNo=-1&pageSize=-1&roleId=8&districtId=${id}`)
    );
    if (error) {
      console.log("KC_DATA==", error);
    }
    const kc = result.data.data.map(({ userId, FirmName }) => ({
      value: userId,
      label: FirmName,
    }));
    setKcUsers(kc);
  };

  const valSchema = Yup.object().shape({
    paytmNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Not a valid Paytm number!")
      .nullable(),
  });

  const fetchRegions = async () => {
    const [error, result] = await asyncWrap(axios.get("masterData/regions"));
    if (error) {
      Swal.fire("Failed to get region data!");
      return;
    }
    const {
      data: { districts, area, states },
    } = result.data;
    setDistrict(
      districts.map(d => ({
        ...d,
        label: d.districtName,
        value: d.districtId,
      }))
    );
    setStateDist(
      districts.map(d => ({
        ...d,
        label: d.districtName,
        value: d.districtId,
      }))
    );
    setAreas(area.map(a => ({ ...a, label: a.areaName, value: a.areaId })));

    setStates(
      states.map(st => ({ ...st, label: st.stateName, value: st.stateId }))
    );
  };

  const stateDistFilter = (value, setFieldValue) => {
    setFieldValue("stateId", value);
    setDistrict(
      stateDist.filter((item) => item.stateId === value.stateId)
    );
  };

  const fetchEditData = async (id) => {
    const [error, result] = await asyncWrap(axios.get(`userDetails`));

    if (error) {
      Swal.fire("Failed to get user data!");
      return;
    }
    const { data } = result.data;
    if (data.districtId === null) {
    } else {
      fetchKcData(data.districtId);
    }
    setUser({ ...data });
  };

  const fetchUserTypeSetting = async () => {
    const [error, result] = await asyncWrap(axios.get("config"));
    if (error) {
      console.log(error);
    } else {
      // const retailerRequired = result.data.data.filter(
      //   item => item.FlagName === "IsRetailerAllow"
      // )[0].Value;
      const kycAutomaticVerification = result.data.data.filter(
        item => item.FlagName === "KYC Automatic verification"
      )[0].Value;
      setKycAutomaticVerification(kycAutomaticVerification);
      // setIsRetailerRequired(retailerRequired);
    }
  };

  useEffect(() => {
    fetchUserTypeSetting();
  }, []);

  useEffect(() => {
    if (location.state) {
      setRegRoleId(location.state.roleId);
    }
    if (userId) {
      fetchEditData(userId);
    }
    fetchRegions();
    fetchRegisterFields();
    // eslint-disable-next-line
  }, [userId]);

  const handleDateError = (setFieldValue, name, value) => {
    if (!value || value === "dd-mm-yyy" || value === "Invalid date") {
      setFieldValue(name, "");
      return;
    }
    setFieldValue(name, value);
  };

  const getData = () => {
    if (userId) {
      let kycImg,
        profileImg,
        path = "";
      if (user.kycPhotoPath) {
        kycImg = user.kycPhotoPath.split("\\").pop();
      }

      if (user.profilePhotoPath) {
        profileImg = user.profilePhotoPath.split("\\").pop();
      }

      let roleId = user.roleId;
      if (location.state) {
        roleId = location.state.roleId;
      }

      const data = {
        ...user,
        roleId,
        PanNumber: user.PancardNumber,
        aadharBackPhoto: user.Aadharcardbackimage,
        AadharCardBackImage: user.Aadharcardbackimage,
        AadharCardFrontImage: user.Aadharcardfrontimage,
        aadharFrontPhoto: user.Aadharcardfrontimage,
        AadharcardNumber: user.Aadharcardnumber,
        panBackPhoto: user.PancardbackImage,
        PancardBackImage: user.PancardbackImage,
        PancardFrontImage: user.PancardFrontimage,
        panFrontPhoto: user.PancardFrontimage,
        gender: user.gender ? user.gender.toLowerCase() : "male",
        beneficiaryDetail: {
          name: user.NameofAccountHolder ? user.NameofAccountHolder : "",
          bankName: user.BankName ? user.BankName : "",
          accountNumber: user.AccountNumber ? user.AccountNumber : "",
          ifscNumber: user.IfscNumber ? user.IfscNumber : "",
        },
        email: user.Email,
        kycTypeId: user.KycTypeId ? user.KycTypeId : 1,
        whatappNumber: user.whatsAppMobileNumber,
        pincode: user.pinCode,
        paytmNumber: user.PaytmNumber,
        language: initialValues.language,
        districtId: {
          value: user.districtId || "",
          label: user.districtName || "",
        },
        kycphoto: path + kycImg,
        profilePhoto: path + profileImg,
        dateOfBirth: moment.utc(user.dateOfBirth).format("YYYY-MM-DD"),
        aniversary:
          !user.aniversary || user.aniversary === "dd-mm-yyyy"
            ? ""
            : moment.utc(user.aniversary).format("YYYY-MM-DD"),
        areaId: { value: user.areaIds || "", label: user.areaNames || "" },
        kcUserIds: convertManagerForSelect(
          user.ManageByUsersIds,
          user.ManageByUsersName
        ),
        stateId: {
          value: user.StateId || "",
          label: user.StateName || "",
        },
      };
      return data;
    } else {
      const data = { ...initialValues };
      return data;
    }
  };

  const handleSelectState = () => {
    const selectdist = state.filter(value => {
      return (
        value.stateName.toLocaleLowerCase() ===
        location.state?.userdata?.split_address.state.toLocaleLowerCase()
      );
    });
    return selectdist
      ? {
          value: selectdist[0]?.stateId || "",
          label: selectdist[0]?.stateName || "",
        }
      : [];
  };

  const handleSelectDistrict = () => {
    const selectdistrictdata = district.filter(value => {
      return (
        value.districtName.toLocaleLowerCase() ===
        location.state?.userdata?.split_address.dist.toLocaleLowerCase()
      );
    });
    return selectdistrictdata
      ? {
          value: selectdistrictdata[0]?.districtId || "",
          label: selectdistrictdata[0]?.districtName || "",
        }
      : [];
  };

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);

    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleImageData = async () => {
    const formData = new FormData();
    formData.append("file", dataURItoBlob(location.state?.userdata.photo_link));
    const [error, result] = await asyncWrap(axios.post("/upload", formData));
    if (error) {
      Swal.fire(error.response.data.message);
      return;
    }

    const {
      data: { fileUrl },
    } = result;
    setIntialfileUrl(fileUrl);
  };

  useEffect(() => {
    if (location.state?.userdata) {
      handleImageData();
    }
    //eslint-disable-next-line
  }, []);

  const initialValues = {
    city: "",
    pincode: location.state?.userdata?.split_address.pincode || "",
    gender: location.state?.userdata?.gender === "M" ? "male" : "female",
    aniversary: "",
    whatappNumber: "",
    dateOfBirth:
      moment(location.state?.userdata?.dob, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ) || "",
    kycTypeId: 2,
    kycNumber: location.state?.aadharNumber || "",
    kycPhotoPath: "",
    kycphoto: "",
    profilePhoto: intialfileUrl || "",
    profilePhotoPath: intialfileUrl || "",
    beneficiaryDetail: {
      name: "",
      accountNumber: "",
      ifscNumber: "",
      bankName: "",
    },
    paytmNumber: "",
    language: "English",
    email: "",
    roleId: id || "7",
    userId: userId || 0,
    fullName: location.state?.userdata?.name || "",
    address: location.state?.userdata?.address || "",
    districtId: handleSelectDistrict() || [],
    mobileNumber: location.state?.mobileNumber || "",
    areaId: [],
    kcUserIds: [],
    beatName: "",
    stateId: handleSelectState() || [],
    AlterNetNumber: "",
    Salutation: "",
    PrimaryMobile: "",
    SecondaryMobile: "",
    LandlineNumber: "",
    FacebookId: "",
    InstagramId: "",
    LinkedInProfile: "",
    TwittterHandle: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    UpiId: "",
    PanNumber: "",
    panFrontPhoto: "",
    PancardFrontImage: "",
    panBackPhoto: "",
    PancardBackImage: "",
    aadharFrontPhoto: "",
    aadharBackPhoto: "",
    AadharCardFrontImage: "",
    AadharCardBackImage: "",
    AadharcardNumber: "",
    CancelledCheaquePhoto: "",
  };


  const handleStateChange = (value, setFieldValue) => {
    setFieldValue("districtId", value);
    fetchKcData(value.value);
    setDistArea(
      areas.filter((item) => value.districtId === item.districtId)
    );
  };

  // const deletePaymentDetails = async (
  //   type: string,
  //   userId: string,
  //   setFieldValue
  // ) => {
  //   try {
  //     const response = await axios({
  //       method: "DELETE",
  //       url: "users/deletePayment",
  //       data: { type, userId },
  //     });

  //     message.success({
  //       content: response.data.message,
  //       style: { marginTop: "5vh" },
  //     });

  //     if (type === "Paytm") {
  //       setFieldValue("paytmNumber", "");
  //     } else if (type === "Bank") {
  //       setFieldValue("beneficiaryDetail.bankName", "");
  //       setFieldValue("beneficiaryDetail.accountNumber", "");
  //       setFieldValue("beneficiaryDetail.name", "");
  //       setFieldValue("beneficiaryDetail.ifscNumber", "");
  //     } else if (type === "Upi") {
  //       setFieldValue("UpiId", "");
  //     }
  //   } catch (error) {
  //     message.error({
  //       content: `Failed to delete ${type} payment detail`,
  //       style: { marginTop: "5vh" },
  //     });
  //   }
  // };

  const onSubmit = async (values, actions) => {
    const { areaId, beneficiaryDetail, districtId, kcUserIds, stateId } =
      values;
    let pancardpattern = new RegExp(/[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/);
    if (values.PanNumber && !pancardpattern.test(values.PanNumber)) {
      Swal.fire("Please enter valid pan number");
      return;
    }
    if (kycAutomaticVerification === "true") {
      const [panError, panResult] = await asyncWrap(
        axios.post("/verifyKyc/verify-pan", {
          panNumber: values.PanNumber,
        })
      );

      if (!panResult) {
        Swal.fire(panError.response.data.data.message);
        return;
      }
      if (!panResult.data.data.valid) {
        Swal.fire(panError.response.data.data.message);
        return;
      } else {
        setPanVerification(true);
      }
    }

    if (isRequiredField("District") && !districtId) {
      Swal.fire("Please select a district & area");
      return;
    }
    if (isRequiredField("State") && !stateId) {
      Swal.fire("Please select a state!");
      return;
    }
    if (isRequiredField("Dealer") && !kcUserIds) {
      Swal.fire("Please select a dealer!");
      return;
    }
    let accountNumberPattern = new RegExp(/^[0-9]{8,16}$/);
    let ifscodePattern = new RegExp(/[A-Za-z]{4}0[A-Z0-9]{6}$/);
    if (
      beneficiaryDetail.accountNumber !== "" &&
      !accountNumberPattern.test(beneficiaryDetail.accountNumber)
    ) {
      Swal.fire("Invalid account number! Must be under 8-16 digits.");
      return;
    } else if (
      beneficiaryDetail.ifscNumber !== "" &&
      !ifscodePattern.test(beneficiaryDetail.ifscNumber)
    ) {
      Swal.fire("Invalid bank IFSC code!");
      return;
    }

    var valuesToSend = {
      kcUserIds: kcUserIds ? kcUserIds.map((item) => item.value) : [],
      paytmDetails: values.paytmNumber,
      name: values.fullName,
      dob: values.dateOfBirth,
      city: values.city,
      district: districtId.value,
      addressLine1: values.address,
      state: stateId.stateName,
      profilePic: values.profilePhotoPath,
      kycNumber: values.kycNumber,
      kycPhotoPath: values.kycPhotoPath,
      anniversary: values.aniversary,
      language: values.language,
      whatappNumber: values.whatappNumber,
      gender: values.gender,
      pinCode: values.pincode,
      email: values.email,
      kycTypeId: values.kycTypeId,
      userType: "electrician",
      phoneNumber: String(values.mobileNumber),
      areaId: areaId.value,
      alternateNumber: values.AlterNetNumber,
      panNumber: values.PanNumber,
      pancardBack: values.panBackPhoto,
      pancardFront: values.panFrontPhoto,
      salutation: values.Salutation,
      primaryMobile: values.PrimaryMobile,
      secondaryMobile: values.SecondaryMobile,
      countryId: 1,
      landlineNumber: values.LandlineNumber,
      facebookId: values.FacebookId,
      instagramId: values.InstagramId,
      linkedInProfile: values.LinkedInProfile,
      twitterHandle: values.TwittterHandle,
      firstName: values.FirstName,
      lastName: values.LastName,
      aadharBack: values.aadharBackPhoto,
      aadharFront: values.aadharFrontPhoto,
      aadharNumber: values.AadharcardNumber,
      middleName: values.MiddleName,
      beatId: values.beatName,
      upiId: values.UpiId,
      cancelledChequePhoto: values.CancelledCheaquePhoto,
      mobileNumber: String(values.mobileNumber),
      isaadharverify: location.state?.userdata ? 1 : 0,
      ispanverify: panVerification ? 1 : 0,
    };

    actions.setSubmitting(true);
    const [error, result] = await asyncWrap(
      axios({
        method: "POST",
        url: "editProfile",
        data: beneficiaryDetail.accountNumber
          ? { ...valuesToSend, beneficiaryDetail }
          : { ...valuesToSend },
      })
    );
    actions.setSubmitting(false);
    if (!error) {
      Swal.fire(result?.data?.message);
      if (!userId) {
        props.history.push("/login");
      }
      props.history.push(`/profile`);
    } else {
      Swal.fire("something Went Wrong!");
    }
  };

  const isDisabled = () => {
    return false;
  };

  const fetchRegisterFields = async () => {
    const [error, result] = await asyncWrap(
      axios({
        method: "GET",
        url: "cmsValues",
        params: {
          cmsType: "RegistrationFields",
        },
      })
    );

    if (!result) {
      console.log(error);
      message.error({
        content: "Failed to fetch fields data!",
        style: { marginTop: "5vh" },
      });
    } else {
      setRegisterFields(result.data.data.RegistrationFields.Descriptions);
    }
  };

  if (!registerFields) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      >
        <Spin spinning tip="Loading..." />
      </div>
    );
  }

  const isVisibleField = (val) => {
    const userRole = regRoleId === "5" ? "Distributor" : "Influencer";
    return registerFields[userRole].filter(item => val === item.ControlName)[0]
      .isVisible;
  };

  const isRequiredField = (val) => {
    const userRole = regRoleId === "5" ? "Distributor" : "Influencer";
    return registerFields[userRole].filter(item => val === item.ControlName)[0]
      .isRequired;
  };

  return (
    <>
      <Card
        style={{ marginTop: "0px", marginBottom: "50px", textAlign: "start" }}
        // title={userId ? "Edit User" : "Add User"}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            style={{
              height: "60px",
            }}
            key={cmsValues?.Logo.CMSType}
            src={cmsValues?.Logo.URL}
            alt=""
          />
          {userId ? (
            <div
              style={{
                color: "#434343",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              className="my-4"
            >
              Edit User
            </div>
          ) : (
            <div
              style={{
                color: "#434343",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              className="my-4"
            >
              Complete Registration
            </div>
          )}
        </div>
        <Formik
          onSubmit={onSubmit}
          initialValues={getData()}
          enableReinitialize={true}
          validationSchema={valSchema}
        >
          {({ values, setFieldValue, isSubmitting, touched, errors }) => (
            <Form>
              {/* {isVisibleField("User Role") && (
                <Col xs="12" md="9">
                  <FormGroup row>
                    <Field
                      style={{
                        textAlign: "start",
                        padding: "13px",
                        border: "1px solid #B0B0B0",
                      }}
                      as="select"
                      id="roleId"
                      name="roleId"
                      className="form-control"
                      required={isRequiredField("User Role")}
                      onChange={e => {
                        let val = e.target.value;
                        console.log();
                        let changeLocation;
                        if (val === "6") {
                          if (!userId) {
                            changeLocation = {
                              pathname: "/add-retailer",
                              state: {
                                roleId: val,
                                userdata: location?.state,
                              },
                            };
                          } else {
                            changeLocation = {
                              pathname: `/edit-retailer/${userId}`,
                              state: { roleId: val },
                            };
                          }
                          props.history.push(changeLocation);
                        } else {
                          setRegRoleId(val);
                          setFieldValue("roleId", val);
                        }
                      }}
                    >
                      <option>Choose User Type</option>
                      <option value="5">Distributor</option>
                      {isRetailerRequired === "true" && (
                        <option value="6">Retailer</option>
                      )}
                      <option value="7">Influencer</option>
                    </Field>
                  </FormGroup>
                </Col>
              )} */}
              {isVisibleField("Full Name") && (
                <FormGroup floating row>
                  {/* <Col xs="12" md="9"> */}
                  <Field
                    style={{
                      textAlign: "start",
                      border: "1px solid #B0B0B0",
                    }}
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Full Name"
                    as={Input}
                    required={isRequiredField("Full Name")}
                  ></Field>
                  <Label style={{ textAlign: "start" }} htmlFor="fullName">
                    Full Name
                  </Label>
                  {/* </Col> */}
                </FormGroup>
              )}
              {isVisibleField("Phone Number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      readOnly
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Phone Number")}
                      type="number"
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="Phone Number"
                      as={Input}
                    />
                    <Label
                      htmlFor="mobileNumber"
                      style={{ textAlign: "start" }}
                    >
                      Phone Number
                    </Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("City") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("City")}
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City"
                      as={Input}
                    />
                    <Label style={{ textAlign: "start" }} htmlFor="city">
                      City
                    </Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("State") && (
                <FormGroup row>
                  <Col xs="12" md="9" className="p-0">
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                          padding: "10px",
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "0px",
                        }),
                      }}
                      placeholder="state"
                      options={state}
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      value={values.stateId}
                      onChange={val => {
                        stateDistFilter(val, setFieldValue);
                      }}
                    />
                  </Col>
                </FormGroup>
              )}
              {isVisibleField("District") && (
                <FormGroup row>
                  <Col xs="12" md="9" className="p-0">
                    <Select
                      isDisabled={!values.stateId.stateId}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                          padding: "10px",
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "0px",
                        }),
                      }}
                      placeholder="District"
                      options={district}
                      isSearchable
                      closeMenuOnSelect
                      value={values.districtId}
                      onChange={val => {
                        handleStateChange(val, setFieldValue);
                      }}
                    />
                  </Col>
                </FormGroup>
              )}
              {isVisibleField("Area") && (
                <FormGroup row>
                  {/* <Col md="3" xs="12">
                    <Label>Area</Label>
                  </Col> */}
                  <Col xs="12" md="9" className="p-0">
                    <Select
                      isDisabled={!values.districtId.districtId}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                          padding: "10px",
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "0px",
                        }),
                      }}
                      onChange={(val) => {
                        setFieldValue("areaId", val);
                      }}
                      value={values.areaId}
                      options={distArea}
                      isSearchable={true}
                      closeMenuOnSelect={true}
                      placeholder="Select district first.."
                    />
                  </Col>
                </FormGroup>
              )}
              <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
                Mandatory Details
              </h6>
              {/* <hr /> */}
              {isVisibleField("KYC Document Type") && (
                <FormGroup row>
                  {/* <Col md="3">
                    <Label htmlFor="kycTypeId">KYC Documnet Type</Label>
                  </Col> */}
                  <Col xs="12" md="9" className="p-0">
                    <Field
                      style={{
                        textAlign: "start",
                        padding: "13px",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("KYC Document Type")}
                      as="select"
                      id="kycTypeId"
                      name="kycTypeId"
                      className="form-control"
                    >
                      <option value="1">PAN Card</option>
                      <option value="2">Aadhar Card</option>
                    </Field>
                  </Col>
                </FormGroup>
              )}

              {isVisibleField("KYC Number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    {/* <Col md="3">
                    
                  </Col> */}
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("KYC Number")}
                      type="text"
                      id="kycNumber"
                      name="kycNumber"
                      placeholder="KYC Number"
                      as={Input}
                      invalid={!!(touched["kycNumber"] && errors["kycNumber"])}
                    />
                    <Label htmlFor="kycNumber">KYC Number</Label>
                    {touched["kycNumber"] && errors["kycNumber"] && (
                      <FormFeedback>{errors["kycNumber"]}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              )}

              {isVisibleField("Pan Number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    {/* <Col md="3">
                    
                  </Col> */}
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Pan Number")}
                      type="text"
                      id="PanNumber"
                      name="PanNumber"
                      placeholder="PAN Number"
                      as={Input}
                    />
                    <Label htmlFor="kycNumber">PAN Number</Label>
                  </FormGroup>
                </Col>
              )}

              {isVisibleField("AAdhar Card Number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("AAdhar Card Number")}
                      type="text"
                      id="AadharcardNumber"
                      name="AadharcardNumber"
                      placeholder="Aadhar Number"
                      as={Input}
                    />
                    <Label htmlFor="AadharcardNumber">Aadhar card Number</Label>
                  </FormGroup>
                </Col>
              )}
              {/* <hr /> */}
              {isVisibleField("Pin Code") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Pin Code")}
                      type="number"
                      id="pincode"
                      name="pincode"
                      placeholder="Pincode"
                      as={Input}
                    />
                    <Label htmlFor="pincode">Pincode</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Address") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Address")}
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Address"
                      as={Input}
                    />
                    <Label htmlFor="address">Address</Label>
                  </FormGroup>
                </Col>
              )}
              <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
                Personal Details
              </h6>
              {/* <hr /> */}
              {isVisibleField("Beat ControlName of User") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Beat ControlName of User")}
                      type="text"
                      id="beatName"
                      name="beatName"
                      placeholder="Beat Name of User"
                      as={Input}
                    />
                    <Label htmlFor="beatName">Beat Name of User</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Whatsapp Number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Whatsapp Number")}
                      type="number"
                      id="whatappNumber"
                      name="whatappNumber"
                      placeholder="Whatsapp Number"
                      as={Input}
                    />
                    <Label htmlFor="whatappNumber">
                      Whatsapp Number{" "}
                      <small className="text-muted">Optional</small>
                    </Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Gender") && (
                <FormGroup row>
                  {/* <Col md="3">
                    <Label htmlFor="gender">Gender</Label>
                  </Col> */}
                  <Col xs="12" md="9" className="p-0">
                    <Field
                      style={{
                        textAlign: "start",
                        padding: "13px",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Gender")}
                      as="select"
                      id="gender"
                      name="gender"
                      className="form-control"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Field>
                  </Col>
                </FormGroup>
              )}
              {isVisibleField("Language") && (
                <FormGroup row>
                  <Col xs="12" md="9" className="p-0">
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                        padding: "13px",
                      }}
                      required={isRequiredField("Language")}
                      as="select"
                      className="form-control"
                      name="language"
                      id="select"
                    >
                      <option value="English">English</option>
                      <option value="Hindi">Hindi</option>
                    </Field>
                  </Col>
                </FormGroup>
              )}
              {isVisibleField("Aniversary") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Aniversary")}
                      type="date"
                      id="aniversary"
                      name="aniversary"
                      as={Input}
                      onChange={(e) =>
                        handleDateError(
                          setFieldValue,
                          "aniversary",
                          e.target.value
                        )
                      }
                    />
                    <Label htmlFor="aniversary">
                      Anniversary <small className="text-muted">Optional</small>
                    </Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Date of Birth") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Date of Birth")}
                      type="date"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      as={Input}
                      onChange={(e) =>
                        handleDateError(
                          setFieldValue,
                          "dateOfBirth",
                          e.target.value
                        )
                      }
                    />
                    <Label htmlFor="dateOfBirth">Date of birth</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Email") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Email")}
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      as={Input}
                    />
                    <Label htmlFor="email">
                      Email <small className="text-muted">Optional</small>
                    </Label>
                  </FormGroup>
                </Col>
              )}

              <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
                Payment Details{" "}
                <small className="text-muted small">Optional</small>
              </h6>
              {/* <hr /> */}
              {isVisibleField("Paytm Number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Paytm Number")}
                      type="number"
                      id="paytmNumber"
                      name="paytmNumber"
                      placeholder="Paytm Number"
                      as={Input}
                      disabled={isDisabled()}
                      invalid={
                        !!(touched["paytmNumber"] && errors["paytmNumber"])
                      }
                    />
                    <Label htmlFor="paytmNumber">Paytm Number</Label>
                    {touched["paytmNumber"] && errors["paytmNumber"] && (
                      <FormFeedback>{errors["paytmNumber"]}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("ControlName of your bank") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    {/* {isDisabled() && (
                    <Col xs="12" md="1">
                      <Tooltip title="Clear bank details">
                        <MinusCircleTwoTone
                          twoToneColor="red"
                          onClick={() =>
                            deletePaymentDetails("Bank", userId, setFieldValue)
                          }
                        />
                      </Tooltip>
                    </Col>
                  )} */}
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("ControlName of your bank")}
                      type="text"
                      id="nameBank"
                      name="beneficiaryDetail.bankName"
                      placeholder="Name of bank"
                      as={Input}
                    />
                    <Label htmlFor="nameBank">Name of your bank</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Bank account number") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Bank account number")}
                      type="text"
                      id="accountNumber"
                      name="beneficiaryDetail.accountNumber"
                      placeholder="Account number"
                      as={Input}
                      disabled={isDisabled()}
                    />
                    <Label htmlFor="accountNumber">Bank Account Number</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Full ControlName as per bank") && (
                <Col xs="12" md="9">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Full ControlName as per bank")}
                      type="text"
                      id="name-bank"
                      name="beneficiaryDetail.name"
                      placeholder="Fullname as per bank account"
                      as={Input}
                    />
                    <Label htmlFor="name-bank">Fullname as per bank</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Bank IFSC Code") && (
                <Col xs="12" md="9" className="p-0">
                  <FormGroup floating row>
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #B0B0B0",
                      }}
                      required={isRequiredField("Bank IFSC Code")}
                      type="text"
                      id="ifscNumber"
                      name="beneficiaryDetail.ifscNumber"
                      placeholder="Bank IFSC"
                      as={Input}
                    />
                    <Label htmlFor="ifscNumber">Bank IFSC Code</Label>
                  </FormGroup>
                </Col>
              )}

              {values.roleId === "7" && isVisibleField("Dealer") && (
                <FormGroup row>
                  {/* <Col md="3">
                    <Label htmlFor="roleId">Dealers</Label>
                  </Col> */}
                  <Col xs="12" md="9" className="p-0">
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#3A69DD" : "#B0B0B0",
                          padding: "10px",
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "0px",
                        }),
                      }}
                      options={kcUsers}
                      value={values.kcUserIds}
                      onChange={val => setFieldValue("kcUserIds", val)}
                      isMulti={true}
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      placeholder="Select a district first"
                    />
                  </Col>
                </FormGroup>
              )}
              {/* <br /> */}
              <h6 style={{ color: "#3A69DD" }} className="mt-4 mb-3 fw-bold">
                Other Optional Details
              </h6>
              {/* <hr /> */}
              {isVisibleField("Alternate Phone Number") && (
                <FormikInput
                  label="Alternate Phone Number"
                  errors={errors["AlterNetNumber"]}
                  name="AlterNetNumber"
                  touched={touched["AlterNetNumber"]}
                  type="tel"
                  required={isRequiredField("Alternate Phone Number")}
                />
              )}
              {isVisibleField("Salutation") && (
                <FormikInput
                  label="Salutation"
                  errors={errors["Salutation"]}
                  name="Salutation"
                  touched={touched["Salutation"]}
                  type="text"
                  required={isRequiredField("Salutation")}
                />
              )}
              {isVisibleField("Facebook Profile URL") && (
                <FormikInput
                  label="Facebook Profile URL"
                  errors={errors["FacebookId"]}
                  name="FacebookId"
                  touched={touched["FacebookId"]}
                  type="text"
                  required={isRequiredField("Facebook Profile URL")}
                />
              )}
              {isVisibleField("Instagram Id") && (
                <FormikInput
                  label="Instagram Id"
                  errors={errors["InstagramId"]}
                  name="InstagramId"
                  touched={touched["InstagramId"]}
                  type="text"
                  required={isRequiredField("Instagram Id")}
                />
              )}
              {isVisibleField("LinkDIn Profile URL") && (
                <FormikInput
                  label="LinkedIn Profile URL"
                  errors={errors["LinkedInProfile"]}
                  name="LinkedInProfile"
                  touched={touched["LinkedInProfile"]}
                  type="text"
                  required={isRequiredField("LinkDIn Profile URL")}
                />
              )}
              {isVisibleField("Twitter Handle ") && (
                <FormikInput
                  label="Twittter Handle"
                  errors={errors["TwittterHandle"]}
                  name="TwittterHandle"
                  touched={touched["TwittterHandle"]}
                  type="text"
                  required={isRequiredField("Twitter Handle ")}
                />
              )}
              {isVisibleField("First ControlName") && (
                <FormikInput
                  label="First Name"
                  errors={errors["FirstName"]}
                  name="FirstName"
                  touched={touched["FirstName"]}
                  type="text"
                  required={isRequiredField("First ControlName")}
                />
              )}
              {isVisibleField("Middle ControlName") && (
                <FormikInput
                  label="Middle Name"
                  errors={errors["MiddleName"]}
                  name="MiddleName"
                  touched={touched["MiddleName"]}
                  type="text"
                  required={isRequiredField("Middle ControlName")}
                />
              )}
              {isVisibleField("Last ControlName") && (
                <FormikInput
                  label="Last Name"
                  errors={errors["LastName"]}
                  name="LastName"
                  touched={touched["LastName"]}
                  type="text"
                  required={isRequiredField("Last ControlName")}
                />
              )}
              {isVisibleField("UPI Id") && (
                <Col xs="12" md="9" className="p-0">
                  <FormGroup floating row>
                    {/* {isDisabled() && (
                    <Col xs="12" md="1">
                      <Tooltip title="Clear UPI Id">
                        <MinusCircleTwoTone
                          twoToneColor="red"
                          onClick={() =>
                            deletePaymentDetails("Upi", userId, setFieldValue)
                          }
                        />
                      </Tooltip>
                    </Col>
                  )} */}
                    <Field
                      style={{
                        textAlign: "start",
                        border: "1px solid #b0b0b0",
                      }}
                      type="text"
                      id="UpiId"
                      name="UpiId"
                      as={Input}
                      placeholder="Upi Id"
                      disabled={isDisabled()}
                      required={isRequiredField("UPI Id")}
                    />
                    <Label htmlFor="UpiId">UPI Id</Label>
                  </FormGroup>
                </Col>
              )}
              {isVisibleField("Primary Mobile Number") && (
                <FormikInput
                  label="Primary Mobile Number"
                  errors={errors["PrimaryMobile"]}
                  name="PrimaryMobile"
                  touched={touched["PrimaryMobile"]}
                  type="tel"
                  required={isRequiredField("Primary Mobile Number")}
                />
              )}
              {isVisibleField("Secoundary Mobile Number") && (
                <FormikInput
                  label="Secondary Mobile Number"
                  errors={errors["SecondaryMobile"]}
                  name="SecondaryMobile"
                  touched={touched["SecondaryMobile"]}
                  type="tel"
                  required={isRequiredField("Secoundary Mobile Number")}
                />
              )}
              {isVisibleField("Landline Number") && (
                <FormikInput
                  label="Landline Number"
                  errors={errors["LandlineNumber"]}
                  name="LandlineNumber"
                  touched={touched["LandlineNumber"]}
                  type="tel"
                  required={isRequiredField("Landline Number")}
                />
              )}
              {/* <hr /> */}
              <div className="d-flex flex-row justify-content-center">
                <Button
                  style={{
                    marginRight: "10px",
                    background: "#3A69DD",
                    padding: "6px 20px",
                  }}
                  type="submit"
                  color="primary"
                  className="mr5 rounded-5"
                  disabled={isSubmitting || userApproved}
                >
                  <i className="fa fa-dot-circle-o"></i>
                  Submit
                </Button>
                {isSubmitting ? <Spinner color="warning" /> : null}
                <Button
                  style={{
                    padding: "6px 20px",
                  }}
                  className="rounded-5"
                  type="reset"
                  color="danger"
                >
                  <i className="fa fa-ban"></i> Reset
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default AddEndUser;
