import React, { useEffect, useState } from 'react';
import { Spinner } from "reactstrap";
import axios from 'axios';
import Swal from "sweetalert2";

import { asyncWrap } from '../../utils/utils';

function YearEndBonanzaSecond() {
    const [coilCoins, setCoilCoins] = useState(null);
    const [loading, setLoading] = useState(false);

    const getUser = async () => {
        setLoading(true)
        const [error, result] = await asyncWrap(axios.get("/userDetails"));
        if (error) {
            Swal.fire("Something went wrong while fetching scan details!");
            console.log(error);
            return;
        }
        if (!result.data.data.CoilScanFrom1stJan2025To31March2025) {
            setCoilCoins(0);
            return;
        } else {
            setCoilCoins(Number(result.data.data.CoilScanFrom1stJan2025To31March2025));
        }
        setLoading(false)
    }

    useEffect(() => {
        getUser();
    }, [])

    if (loading) {
        return <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    }


    return (
        <>
            <div className='d-flex text-center flex-column'>
                {console.log({ coilCoins })}
                <table className='custom-table'>
                    <thead>
                        <tr style={{ fontSize: '16px' }}>
                            <th style={{ border: '1px solid #000', textAlign: 'start' }}>
                                Slab
                            </th>
                            <th style={{ border: '1px solid #000', textAlign: 'start' }}>
                                Slab Coil Count Minimum
                            </th>
                            <th style={{ border: '1px solid #000', textAlign: 'center' }}>
                                Gift
                            </th>
                            <th style={{ border: '1px solid #000', textAlign: 'center' }}>
                                How many coils scanned (1st January to 31st March)
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '16px', textAlign: "center" }}>
                        <tr>
                            <td>L0</td>
                            <td style={{ textAlign: "center" }}>0</td>
                            <td>Not eligible</td>
                            <td>{coilCoins <= 30 ? (coilCoins === 0 ? "Not achieved" : coilCoins) : ""}</td>
                        </tr>
                        <tr>
                            <td>L1</td>
                            <td style={{ textAlign: "center" }}>31</td>
                            <td>1200 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 31 && coilCoins < 61) ? coilCoins : ""}</td>
                        </tr>

                        <tr>
                            <td>L2</td>
                            <td style={{ textAlign: "center" }}>61</td>
                            <td>2800 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 61 && coilCoins < 101) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L3</td>
                            <td style={{ textAlign: "center" }}>101</td>
                            <td>4800 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 101 && coilCoins < 201) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L4</td>
                            <td style={{ textAlign: "center" }}>201</td>
                            <td>10400 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 201 && coilCoins < 301) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L5</td>
                            <td style={{ textAlign: "center" }}>301</td>
                            <td>16800 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 301 && coilCoins < 501) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L6</td>
                            <td style={{ textAlign: "center" }}>501</td>
                            <td>30000 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 501 && coilCoins < 776) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L7</td>
                            <td style={{ textAlign: "center" }}>776</td>
                            <td>49600 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 776 && coilCoins < 1251) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L8</td>
                            <td style={{ textAlign: "center" }}>1251</td>
                            <td>80800 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 1251 && coilCoins < 2001) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L9</td>
                            <td style={{ textAlign: "center" }}>2001</td>
                            <td>136000 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 2001 && coilCoins < 3001) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L10</td>
                            <td style={{ textAlign: "center" }}>3001</td>
                            <td>210000 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 3001 && coilCoins < 5001) ? coilCoins : ""}</td>
                        </tr>
                        <tr>
                            <td>L11</td>
                            <td style={{ textAlign: "center" }}>5001</td>
                            <td>360000 points to be added in Udaan 5.0</td>
                            <td>{(coilCoins >= 5001) ? coilCoins : ""}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ height: '100vh', width: '100%' }}>
                <iframe
                    src='https://drive.google.com/file/d/1pKrPvekADv-TwoeNybLZXS7Rhzr3iRFX/preview'
                    style={{ height: '100%', width: '100%', border: 'none' }}
                    title='PDF Viewer'
                />
            </div>
        </>
    );
}

export default YearEndBonanzaSecond;
