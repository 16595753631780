import { useEffect, useState } from "react";
import { Modal, Image, Button } from "react-bootstrap";
import { Input, Label } from "reactstrap";
import close from "/assets/images/popclose.png";
import { asyncWrap } from "../../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";
import { Spin } from "antd";

const EditRedeemDetails = ({ show, setShow, redeemType }) => {
  const [detailText, setDetailText] = useState("");
  const [loading, setLoading] = useState(false);
  const [paytmNumber, setPaytmNumber] = useState();
  const [upiId, setUpiId] = useState();
  const [account, setAccount] = useState();
  const [ifsc, setIfsc] = useState();
  const [bankName, setBankName] = useState();
  const [name, setName] = useState();

  const setHeader = () => {
    if (redeemType === "Bank") {
      setDetailText("Add Beneficiary Details");
    } else if (redeemType === "Paytm") {
      setDetailText("Add Paytm Details");
    } else if (redeemType === "Upi") {
      setDetailText("Add UPI Details");
    }
  };

  const getUserDetails = async () => {
    const [error, result] = await asyncWrap(axios.get("/userDetails"));
    if (error?.response?.status === 401) {
      Swal.fire(
        "Session Expired",
        "Please logout. Your session is expired.\n\nGo to the left menu - See the logout option below settings - Click on logout and log in again.",
        "warning"
      );

      return;
    }
    if (!result) {
      console.log(error);
      return;
    }
    setPaytmNumber(result.data.data.paytmDetails.mobile);
    setUpiId(result.data.data.UpiId);
    setBankName(result.data.data.beneficiaryDetail.bankName);
    setAccount(result.data.data.beneficiaryDetail.accountNumber);
    setIfsc(result.data.data.beneficiaryDetail.ifscNumber);
    setName(result.data.data.beneficiaryDetail.name);
  };

  //eslint-disable-next-line
  const isvalidAccount = new RegExp("[0-9]{9,18}");

  //eslint-disable-next-line
  const isvalidIfsc = new RegExp("^[A-Z]{4}[0][A-Z0-9]{6}$");
  const isvalidPaytm = new RegExp(/^[6-9]\d{9}$/);
  const isvalidUpi = new RegExp("[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}");

  const EditDetails = async () => {
    setLoading(true);
    let data;
    if (redeemType === "Bank") {
      if (!isvalidAccount.test(account)) {
        Swal.fire("Please Enter valid Account Number!");
        return;
      }
      if (!isvalidIfsc.test(ifsc)) {
        Swal.fire("Please Enter valid Ifsc Code!");
        return;
      }
      data = {
        beneficiaryDetail: {
          name: name,
          accountNumber: account,
          ifscNumber: ifsc,
          bankName: bankName,
        },
      };
    }
    if (redeemType === "Paytm") {
      if (!isvalidPaytm.test(paytmNumber)) {
        Swal.fire("Please Enter valid Paytm Number!");
        return;
      }
      data = {
        paytmDetails: {
          mobile: paytmNumber,
        },
      };
    }
    if (redeemType === "Upi") {
      if (!isvalidUpi.test(upiId)) {
        Swal.fire("Please input correct upi!");
        return;
      }
      data = {
        upiId: upiId,
      };
    }

    const [error, result] = await asyncWrap(axios.post("/editProfile", data));

    if (!result) {
      Swal.fire(error.response.data.message);
      return;
    }
    Swal.fire(result.data.message);
    setLoading(false);
    setShow(false);
  };

  const type = () => {
    if (redeemType === "Bank") {
      return (
        <div style={{ width: "250px" }}>
          <Label>
            Name
            <Input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Label>
          <Label style={{ marginTop: "10px" }}>
            A/C Number
            <Input
              required
              type="number"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
            />
          </Label>
          <Label style={{ marginTop: "10px" }}>
            IFSC Code
            <Input
              required
              value={ifsc}
              onChange={(e) => setIfsc(e.target.value)}
            />
          </Label>
          <Label style={{ marginTop: "10px" }}>
            Bank Name
            <Input
              required
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </Label>
        </div>
      );
    } else if (redeemType === "Paytm") {
      return (
        <div style={{ width: "250px" }}>
          <Label>
            Paytm Number
            <Input
              required
              type="number"
              value={paytmNumber}
              onChange={(e) => setPaytmNumber(e.target.value)}
            />
          </Label>
        </div>
      );
    } else if (redeemType === "Upi") {
      return (
        <div style={{ width: "250px" }}>
          <Label>
            Upi id
            <Input
              required
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
            />
          </Label>
        </div>
      );
    }
  };

  useEffect(() => {
    setHeader();
    getUserDetails();
    //eslint-disable-next-line
  }, [show]);

  return (
    <Spin spinning={loading}>
      <Modal
        fullscreen={false}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>{detailText}</Modal.Header>
        <Modal.Body>
          <span className="modal-close">
            <Image src={close} alt="" onClick={() => setShow(false)} />
          </span>
          <div>{type()}</div>
          <Button
            onClick={EditDetails}
            style={{ marginTop: "20px", float: "right" }}
            type="primary"
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </Spin>
  );
};

export default EditRedeemDetails;
