import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { asyncWrap } from '../../utils/utils';

function VoucherDetails() {
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    getVoucherDetails();
    //eslint-disable-next-line
  }, []);

  const getVoucherDetails = async () => {
    const [error, result] = await asyncWrap(axios.get('/userDetails'));
    if (error?.response?.status === 401) {
      Swal.fire(
        "Session Expired",
        "Please logout. Your session is expired.\n\nGo to the left menu - See the logout option below settings - Click on logout and log in again.",
        "warning"
      );

      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      // const user_data = result?.data?.data;
      setUserDetails(result?.data?.data);
    }
  };

  return (
    <>
      {userDetails && userDetails?.InstagramId && (
        <>
          <div className='d-flex flex-row justify-content-evenly gap-2 flex-wrap'>
            <>
              <div
                className='d-flex flex-column rounded-2 shadow-sm p-2'
                style={{ width: '50%', height: '195px' }}
              >
                <div className='text-center' style={{ paddingTop: '50px' }}>
                  <p className='mt-1 mb-0' style={{ fontSize: '15px' }}>
                    You Win{' '}
                  </p>
                  <p
                    className='mt-1 mb-0 fs-20 text-bold'
                    style={{ color: '#DC7832', fontSize: '18px' }}
                  >
                    {(userDetails?.InstagramId).split('viewed')[0] || 0}
                  </p>
                </div>
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
}

export default VoucherDetails;
