import { Modal } from 'react-bootstrap';
import CloseModalTab from '../Nav/CloseModalTab';

const CurrentBalance = ({ data, show, setShow }) => {
  return (
    <>
      <Modal fullscreen={true} show={show}>
        <Modal.Body>
          <CloseModalTab onclick={()=>{setShow(false)}} />
          <div className='d-flex text-center flex-column'>
            <div
              style={{
                border: '1px solid #000',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              Current Balance
            </div>
            <div className='d-flex flex-column my-4 mx-auto'>
              <div className='d-flex flex-row'>
                <div style={{ fontWeight: 'bold' }}>
                  Current Balance : {'  '}
                </div>
                <div>&emsp;{data}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CurrentBalance;
